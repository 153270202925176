.customTable {
  border-collapse: collapse;
  border: 2px solid rgb(140 140 140);
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
  width: 100%;
}

.th,
.td {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
  font-size: 20px;
}
